.thankyou-text {
  color: #1b1b1b;
  font-size: 25px;
  font-family: Avenir-black;
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: 0;
}

.congratulation-container {
  background-image: url("../../assets/images/congrats-background.png");
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  margin-top: 1em;
}
.text12 {
  color: #1d1d1b;
  font-family: Avenir-semibold;
  font-weight: 700;
  font-size: 1.1em;
  margin-bottom: 6em;
  margin-left: 2em;
  margin-right: 2em;
}
.yellow-text {
  color: #1d1d1b;
  font-weight: bold;
  margin-bottom: 4em;
  font-family: Avenir-black;
  line-height: 19px;
}
.text13 {
  margin: 0;
  font-family: Avenir-black;
  // font-weight: bold;
  font-size: 24px;
}
.footer-img {
  position: absolute;
  bottom: 0;
  left: -50%;
  transform: translateX(50%);
  width: 100%;
}
