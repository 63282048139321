@font-face {
  font-family: gilroy-bold;
  src: url(../assets/fonts/Gilroy-ExtraBold.otf);
}
@font-face {
  font-family: marcentra;
  src: url(../assets/fonts/MARSCENTRA-EXTRABOLD.OTF);
}
@font-face {
  font-family: Avenir-bold;
  font-weight: normal;
  src: url(../assets/fonts/Avenir.ttc);
}
@font-face {
  font-family: Avenir-semibold;
  src: url(../assets/fonts/Avenir.ttc);
}
@font-face {
  font-family: Avenir;
  src: url(../assets/fonts/Avenir.ttc);
}
@font-face {
  font-family: Avenir-black;
  src: url(../assets/fonts/Avenir-Black.ttf);
}
// @import "mixins";

@mixin hideScroll {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */

  &::-webkit-scrollbar {
    background: transparent; /* Chrome/Safari/Webkit */
    width: 0;
  }
}

body {
  margin: 0;
  margin: 0;
  padding: 0;
  // --green: #03a651;
  // background-color: var(--bg-blue);
  font-family: Avenir;
  // background-color: #33146f;
  // background-image: url(../assets/images/bg/BG1.png);
  // background-color: #be0000;
  background: var(--BG, #fed600);
}
* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

.btn {
  border-radius: 8px;
  outline: none;
  border: none;
  // padding: 12px 30px;
  padding: 10px 45px;
  // font-family: Graphik;
  font-size: 25px;
  // font-style: italic;
  font-weight: 600;
  text-align: center;

  color: #000;
  cursor: pointer;
  transition: all 0.3s;
  &:hover:not(:disabled) {
    transform: scale(1.02);
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
  &.btn-primary {
    border-radius: 10px;
    // border: 1px solid #000;
    // box-shadow: 0px 10px 0px 0px #d72c16;
    background: #e30613;
    // color: white;
    color: #fff;
    // border-width: 1px;
    /* border-style: inset; */
    /* border-color: #fff; */
    // box-shadow: 0 0 0 5px #4e2f8b, inset 0 0 0 1px #fff;
    // border: 2px solid #000;
    font-family: Avenir-semibold;

    margin: 0 auto;
  }
}
.checkbox-container1 {
  display: block;
  position: relative;
  padding-left: 23px;
  cursor: pointer;
  font-size: 12px;
  user-select: none;
  text-align: start;
  color: #1b1b1b;
  font-family: Avenir;
  .link {
    text-decoration: underline;
    cursor: pointer;
  }
  .checkbox {
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    border-radius: 2px;
    // border: 1px solid #ea9b9c;
    background: #fff;
    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 6px;
      top: 2px;
      width: 5px;
      height: 10px;
      border: solid #009640;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
}
// .checkbox-container {
//   display: block;
//   position: relative;
//   padding-left: 25px;
//   cursor: pointer;
//   font-size: 12px;
//   user-select: none;
//   text-align: start;
//   color: #1b1b1b;
//   font-family: Avenir;
//   .link {
//     text-decoration: underline;
//     cursor: pointer;
//   }
// input {
//   position: absolute;
//   opacity: 0;
//   cursor: pointer;
//   height: 0;
//   width: 0;
//   &:checked {
//     ~ {
//       .checkmark {
//         // background-color: white;
//         &:after {
//           display: block;
//         }
//       }
//     }
//   }
// }
// .checkmark {
//   position: absolute;
//   top: 0;
//   left: 0;
//   height: 20px;
//   width: 20px;
//   border-radius: 2px;
//   // border: 1px solid #ea9b9c;
//   background: #fff;
//   &:after {
//     content: "";
//     position: absolute;
//     display: none;
//     left: 6px;
//     top: 2px;
//     width: 5px;
//     height: 10px;
//     border: solid #009640;
//     border-width: 0 2px 2px 0;
//     transform: rotate(45deg);
//   }
// }
// }

.input-group {
  // margin-bottom: 20px;
  text-align: center;
  margin: 0 auto 12px;
}
.input-group1 {
  // margin-bottom: 20px;
  text-align: center;
  margin: 0 auto 12px;
}
.arrow-icon {
  position: absolute;
  right: 50px;
  padding-top: 0.3em;
}
.batchcode {
  position: absolute;
  right: 40px;
  padding-top: 0.4em;
}
.error {
  color: red;
  text-align: center;
  font-family: SpaceGrotesk;
  font-size: 16px;
}

input {
  padding: 10px;
  font-size: 16px;
  // text-align: center;
  text-align: center;
  border: none;
  box-sizing: border-box;
  font-family: Avenir-semibold;
  background: transparent;
  font-weight: 800;
  // background: #ffff;
  border-radius: 8px;
  border: 1px solid #ffff;
  color: #606060;
  width: 100%;
  outline: none;
  background: #ffffff;
  &::placeholder {
    color: #606060;
    font-family: Avenir-semibold;
  }
}

select {
  padding: 10px;
  font-size: 16px;
  // text-align: center;
  text-align: center;
  border: none;
  box-sizing: border-box;
  font-family: Avenir-semibold;
  background: transparent;
  font-weight: 800;
  // background: #ffff;
  border-radius: 8px;
  border: 1px solid #ffff;
  color: #606060;
  width: 100%;
  outline: none;
  background: #ffffff;
  appearance: none;
  &::placeholder {
    color: #606060;
    font-family: Avenir-semibold;
  }
}

.grecaptcha-badge {
  bottom: 40px !important;
  display: none;
}
.Toastify__toast-theme--light {
  display: none;
  background: var(--bg-red);
  color: white;
  &.Toastify__toast--success {
    background: var(--bg-blue);
    display: none;
  }
  .Toastify__toast-theme--default {
    display: none;
    /* Additional styling if needed */
  }
}
.App {
  height: 100svh;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  @media screen and (min-width: 768px) {
    display: block;
    margin: 0 auto;
    max-width: 375px;
    @include hideScroll;
  }
}

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  .popup-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba($color: #000000, $alpha: 0.7);
  }
  .popup-modal {
    position: relative;
    z-index: 1;
    background-color: white;
    width: 80%;
    max-width: 400px;
    border-radius: 0.5rem;
    max-height: 85vh;
    overflow: auto;
    .header {
      width: 100%;
      height: 40px;
      border-bottom: 1px solid lightgray;
      position: sticky;
      top: 0;
      background-color: white;
      border-radius: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      .title {
        font-size: 20px;
        margin: 0;
      }
    }
    .content {
      padding: 1rem;
    }
    .closeBtn {
      position: absolute;
      top: 10px;
      width: 22px;
      right: 10px;
      margin: auto;
      cursor: pointer;
      &:hover {
        transform: scale(1.02);
      }
    }
  }
}
.dropdown {
  z-index: 5;
  background-color: #ffff;
  position: absolute;
  // width: 73%;
  width: 82%;
  display: flex;
  overflow: scroll;
  overflow-x: hidden;
  max-height: 20vh;
  min-height: 5vh;
  // left: 13%;
  border-radius: 5px;
  -webkit-box-shadow: 0px 6px 15px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 6px 15px -3px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 6px 15px -3px rgba(0, 0, 0, 0.75);
  color: #606060;

  ul {
    text-align: start;
    padding: 1rem;
    margin: 0;
    display: flex;
    flex-direction: column;
    row-gap: 2vh;
    width: 100%;
    @media (min-width: 768px) {
      padding: 1rem;
    }

    li {
      list-style-type: none;
      padding: 0.5vh 1vw;
      border-radius: 5px;

      &:hover {
        background-color: #532a16;
      }
    }
    .nf {
      pointer-events: none;
    }
  }
}
