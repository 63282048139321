form {
  // width: 85%;
  // width: 85%;
  // position: absolute;
  // top: 60%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  // width: 85%;
  // width: 85%;
  /* position: absolute; */
  /* top: 60%; */
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  /* align-items: center; */
  display: flex;
  flex: 1 1;
  /* justify-content: center; */
  /* padding: .8rem; */
  text-align: center;
  flex-direction: column;
  // margin-bottom: 7em;
  margin-bottom: 1em;
}
.coming-soon {
  color: #1b1b1b;
  text-align: center;
  font-family: Avenir-black;
  font-size: 28px;
  line-height: normal;
  margin-top: 3em;
  margin-bottom: 0.1em;
  font-weight: bold;
}
.register-page {
  .register-form,
  .otp-form {
    text-align: center;
    // margin: 2em auto;
    // background-color: #210d48;
    border-radius: 16px;
    padding: 1em 2em;
    padding-top: 0;
    width: 100vw;
    // max-width: 330px;
    .form-title {
      color: #1b1b1b;
      text-align: center;
      // font-family: Avenir;
      font-family: Avenir-black;
      font-size: 28px;
      // font-weight: 600;
      line-height: normal;
      // margin: 0.5em 0;
      // margin-top: 0;
      // text-transform: uppercase;
      margin-top: 0.1em;
      margin-bottom: 0.1em;
      font-weight: bold;
    }
    .flex-center1 {
      display: flex;
      align-items: center;
      // justify-content: left;
      justify-content: center;
    }
    .flex-center {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .input-group {
      .text {
        margin-right: 10px;
      }
      label:not(:first-child) {
        margin-left: 10px;
      }
    }
    .tamilnadu-text {
      margin-top: 0;
      color: #1b1b1b;
      font-family: Avenir;
      font-size: 13px;
    }
    .timer-wrapper {
      margin: 30px auto 10px;
      .timer {
        width: 25px;
        // border: 1px solid;
        height: 25px;
        border-radius: 50%;
        font-size: 12px;
        margin-right: 6px;
        color: #009640;
        background-color: #fff;
      }
      span {
        color: #1b1b1b;
        // font-family: gilroy-bold;
        font-size: 12px;
        // font-style: italic;
        font-weight: 600;
        line-height: normal;
        &.link {
          margin-left: 6px;
          cursor: pointer;
          text-decoration-line: underline;
          color: #1b1b1b;
          font-family: "gilroy-bold";
        }
      }
    }
    .reg-lang {
      .reg-lang-title {
        margin-top: 5px;
        margin-bottom: 5px;
        font-family: Myriad Pro;
      }
    }
    .link {
      color: #f4b747;
    }
  }
}

.popup-container {
  // background-color: rgba(0, 0, 0, 0.7);
  // position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  left: 0;
  z-index: 1;
}

.batch-popup {
  // align-items: center;
  // display: flex;
  // justify-content: center;
  // position: fixed;
  // z-index: 100;
  // left: 50%;
  // transform: translateX(-50%);
  align-items: center;
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 100;
  /* left: 50%; */
  /* transform: translateX(-50%); */
  margin-top: 40vh;
  .close {
    bottom: -2.8rem;
    cursor: pointer;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    height: 30px;
    width: 30px;
  }
}
.popup-image {
  // width: 100%;
  // border: 5px solid #fff;
  width: 90%;
  border: 5px solid #fff;
  // transform: translateX(5%);
}

.terms-link {
  color: #1b1b1b;
  font-weight: bold;
}

/* Add styles for the dropdown container */
.state.dropdown {
  position: absolute;
  z-index: 1;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
}

/* Style individual dropdown options */
.state.dropdown ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.state.dropdown li {
  padding: 8px;
  cursor: pointer;
}

.state.dropdown li:hover {
  background-color: #ddd;
}
.snickers-text {
  font-family: Avenir;
  font-size: 28px;
  color: #fff;
  // margin-bottom: 0;
  text-align: left;
  text-transform: uppercase;
}
.additionalText {
  border: 1px solid #f3dbbc;
  border-radius: 10px;
  background: linear-gradient(0deg, #b17e64 0%, #b17e64 100%), #fff;
  min-height: 10vh;
  width: 100%;
  padding: 1em;
  color: #fff;
  font-family: Avenir-semibold;
  margin-bottom: 1em;
  font-size: 18px;
  &::placeholder {
    color: #606060;
    font-family: Avenir-semibold;
    text-transform: uppercase;
  }
}
